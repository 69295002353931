import React, { useState } from "react";
import List from "@mui/material/List";
// import ListItemIcon from "@mui/material/ListItemIcon";
import brief from "../../assets/media/menuIcon/brief.svg";
import explore from "../../assets/media/menuIcon/explore.svg";
import inbox from "../../assets/media/menuIcon/inbox.svg";
import studio from "../../assets/media/menuIcon/studio.svg";
import trending from "../../assets/media/menuIcon/trending.svg";
import brief1 from "../../assets/media/menuactiveicon/brief.svg";
import explore1 from "../../assets/media/menuactiveicon/explore.svg";
import inbox1 from "../../assets/media/menuactiveicon/inbox.svg";
import studio1 from "../../assets/media/menuactiveicon/studio.svg";
import trending1 from "../../assets/media/menuactiveicon/trending.svg";
// import add_active from "../../assets/media/menuactiveicon/add.svg";
import addicon from "../../assets/icons/Add_icon.svg";
import { ListItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SmallModal from "../../Modules/Components/Reusable/Modal/small/SmallModal";

function FooterIcon() {
  const { pathname } = useLocation();
  const [active, setActive] = useState({
    link: pathname,
  });

  const menu = [
    {
      icon: explore,
      icon1: explore1,
      menu: "Explore",
      link: "/dashboard",
    },
    {
      icon: brief,
      icon1: brief1,
      menu: "Brief",
      link: "/brief",
    },
    {
      icon: addicon,
      // icon1: add_active,
      menu: "open",
      btn: true,
    },
    {
      icon: trending,
      icon1: trending1,
      menu: "Trending",
      link: "/trending",
    },
    {
      icon: inbox,
      icon1: inbox1,
      menu: "Inbox",
      link: "/inbox",
    },
    {
      icon: studio,
      icon1: studio1,
      menu: "Studio",
      link: "/studio",
    },
  ];

  const [openSmall, setOpenSmall] = useState(false);

  const handleActive = (item) => {
    setActive(item);
  };

  const handleOpen = () => {
    setOpenSmall(true);
  };

  const drawer = (
    <List>
      {menu.map((item, i) => (
        <ListItem key={i}>
          {item?.btn ? (
            <Link onClick={handleOpen}>
              <img src={item?.icon} className="img-fluid" alt="menuIcon" />
            </Link>
          ) : (
            <Link to={`${item?.link}`} onClick={() => handleActive(item)}>
              {active?.link === item?.link ? (
                <img src={item?.icon1} className="img-fluid" alt="menuIcon" />
              ) : (
                <img src={item?.icon} className="img-fluid" alt="menuIconDifferent" />
              )}
            </Link>
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <div className="footer_sec">
        <div className="icons">{drawer}</div>
      </div>
      <SmallModal
        openSmall={openSmall}
        setOpenSmall={setOpenSmall}
        briefBlog={true}
      />
    </>
  );
}

export default FooterIcon;
