import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { AppBar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "./index.scss";
import Navigation from "../Shared/Navigation";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import search from "../assets/media/menuIcon/search.svg";
import brief from "../assets/media/menuIcon/brief.svg";
import explore from "../assets/media/menuIcon/explore.svg";
// import inbox from "../assets/media/menuIcon/inbox.svg";
import studio from "../assets/media/menuIcon/studio.svg";
import trending from "../assets/media/menuIcon/trending.svg";
import search1 from "../assets/media/menuactiveicon/search.svg";
import AddPostBlackIcon from "../assets/icons/add_post_black.svg";
import brief1 from "../assets/media/menuactiveicon/brief.svg";
import explore1 from "../assets/media/menuactiveicon/explore.svg";
// import inbox1 from "../assets/media/menuactiveicon/inbox.svg";
import studio1 from "../assets/media/menuactiveicon/studio.svg";
import trending1 from "../assets/media/menuactiveicon/trending.svg";
import add_icon from "../assets/icons/add.svg";
import FooterIcon from "../Shared/Footer/FooterIcon";
import SmallModal from "./../Modules/Components/Reusable/Modal/small/SmallModal";
import ResponsiveDrawer from "../Modules/Pages/SearchBar";
import WhiteIcon from "../assets/icons/whiteIcon/search_white.svg";
import WhitExplore from "../assets/icons/whiteIcon/white-explore.svg";
import WhiteBrief from "../assets/icons/whiteIcon/white-brief.svg";
import WhiteTrending from "../assets/icons/whiteIcon/white-trending.svg";
// import WhiteInbox from "../assets/icons/whiteIcon/white-inbox.svg";
import WhiteStudio from "../assets/icons/whiteIcon/white-studio.svg";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;
const drawerWidthMobile = 70;

function PrivateRoute({ children }) {
  const darkMode = useSelector((state) => state.headerMenu.darkMode);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [active, setActive] = useState({
    link: pathname,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticate
  );

  const Lang = useSelector((state) => state.language.lang);
  if (Lang === "ar") {
    var langRight = 0;
    var langLeft = "auto";
  }
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const menu = [
    {
      icon: darkMode ? WhiteIcon : search,
      icon1: search1,
      menu: t("search"),
      link: "/search",
      btn: true,
    },
    {
      icon: darkMode ? WhitExplore : explore,
      icon1: explore1,
      menu: t("Explore"),
      link: "/dashboard",
    },
    {
      icon: darkMode ? WhiteBrief : brief,
      icon1: brief1,
      menu: t("Brief"),
      link: "/brief",
    },
    {
      icon: darkMode ? WhiteTrending : trending,
      icon1: trending1,
      menu: t("Trending"),
      link: "/trending",
    },
    /* {
      icon: darkMode ? WhiteInbox : inbox,
      icon1: inbox1,
      menu: t("Inbox"),
      link: "/inbox",
    }, */
    {
      icon: darkMode ? WhiteStudio : studio,
      icon1: studio1,
      menu: t("Studio"),
      link: "/studio",
    },
  ];

  const handleActive = (item) => {
    setActive(item);
  };

  const handleSearch = (item) => {
    DrawerToggle(item?.menu === "Search" ? true : false);
  };

  const drawer = (
    <List
      style={{
        padding: "0",
        backgroundColor: darkMode ? "#000" : "#fff",
      }}
    >
      {menu.map((item, i) => (
        <ListItem key={i} style={{ padding: "15px 20px" }}>
          {item?.btn ? (
            <Link className="gap-3" onClick={() => handleSearch(item)}>
              {active?.link === item?.link ? (
                <img src={item?.icon1} className="img-fluid" alt="menuIcon" />
              ) : (
                <img src={item?.icon} className="img-fluid" alt="menuIcon" />
              )}
              {!isMobile && (
                <ListItemText
                  primary={item?.menu}
                  style={{
                    color:
                      active?.link === item?.link
                        ? "#E84127"
                        : darkMode
                        ? "white"
                        : "inherit",
                  }}
                />
              )}
            </Link>
          ) : (
            <Link
              className="gap-3"
              to={`${item?.link}`}
              onClick={() => handleActive(item)}
            >
              {active?.link === item?.link ? (
                <img src={item?.icon1} className="img-fluid" alt="menuIcon" />
              ) : (
                <img src={item?.icon} className="img-fluid" alt="menuIcon" />
              )}
              {!isMobile && (
                <ListItemText
                  primary={item?.menu}
                  style={{
                    color:
                      active?.link === item?.link
                        ? "#E84127"
                        : darkMode
                        ? "white"
                        : "inherit",
                  }}
                />
              )}
            </Link>
          )}
        </ListItem>
      ))}
    </List>
  );
  const [mOpen, setMOpen] = React.useState(false);
  const [isClose, setIsClose] = React.useState(false);

  const DrawerToggle = (val) => {
    if (!isClose) {
      setMOpen(!mOpen);
    }
  };

  const [openSmall, setOpenSmall] = useState(false);

  const handleOpen = () => {
    setOpenSmall(true);
  };

  return loggedIn ? (
    pathname === "/profile" ||
    pathname === "/changePassword" ||
    pathname === "/blockedaccounts" ||
    pathname === "/termsandConditions" ||
    pathname === "/helpcenter" ||
    pathname === "/saveItem" ? (
      children
    ) : (
      <>
        <div className={`main ${Lang === "ar" ? "right" : ""}`}>
          <AppBar
            // position="fixed"
            sx={{
              background: { sm: darkMode ? "#000" : `#fff` },
              boxShadow: { sm: `none` },
            }}
            className={`header ${pathname === "/brief" ? "back_header" : ""}`}
          >
            <Navigation
              isClosing={isClosing}
              onSearchClick={() => DrawerToggle(true)}
              handleDrawerToggle={handleDrawerToggle}
            />
          </AppBar>
          <Box
            sx={{
              display: "flex",
              background: { sm: darkMode ? "#000" : `#fff` },
            }}
          >
            <Box
              className="customeNavigation"
              component="nav"
              sx={{
                width: { sm: isMobile ? drawerWidthMobile : drawerWidth },
                flexShrink: { sm: 0 },
                background: { sm: darkMode ? "#000" : `#fff` },
              }}
            >
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: isMobile ? drawerWidthMobile : drawerWidth,
                    height: "100%",
                    backgroundColor: darkMode ? "#000" : "#fff",
                    right: langRight,
                    left: langLeft,
                  },
                }}
                open
              >
                {drawer}
                {!isMobile ? (
                  <button
                    // className="ms-3"
                    style={{
                      borderRadius: "10px",
                      margin: "0 20px",
                      padding: "12px 20px 12px 20px",
                      border: "none",
                      backgroundColor: "#E84127",
                    }}
                    onClick={handleOpen}
                  >
                    <img className="me-3" src={add_icon} alt="" />
                    <span style={{ color: "#FFFFFF" }}>{t("WriteUpload")}</span>
                  </button>
                ) : (
                  <div onClick={handleOpen}>
                    <img
                      className="me-3 add-post-icon-black"
                      src={AddPostBlackIcon}
                      alt=""
                    />
                  </div>
                )}
              </Drawer>
            </Box>
            <Box
              component="main"
              className="main-wrapper"
              sx={{
                flexGrow: 1,
                width: {
                  sm: `calc(100% - ${
                    isMobile ? drawerWidthMobile : drawerWidth
                  }px)`,
                },
              }}
            >
              {children}
              <FooterIcon />
            </Box>
          </Box>

          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
        <SmallModal
          openSmall={openSmall}
          setOpenSmall={setOpenSmall}
          briefBlog={true}
        />
        <ResponsiveDrawer
          handleDrawerToggle={DrawerToggle}
          setIsClose={setIsClose}
          isClose={isClose}
          mOpen={mOpen}
          setMOpen={setMOpen}
        />
      </>
    )
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;
